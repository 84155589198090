/* src/BrowseCategories.css */

.browse-categories {
    padding: 2rem 0;
    max-width: 1200px; /* Set a max-width for the container */
    margin: 0 auto; /* Center the container */
  }
  
  .browse-categories h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem; /* Same as .section-title in product-section */
  }
  
  .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 2rem;
  }
  
  .category {
    flex: 1 1 calc(20% - 1rem);
    margin: 0.5rem;
    box-sizing: border-box;
    text-align: center;
  }
  
  .category img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .category-title {
    font-size: 1.25rem; /* Same as .card-title in product-section */
    margin: 0.75rem 0;
  }
  
  .category-text {
    font-size: 1rem; /* Same as .card-text in product-section */
  }
  
  .infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .info {
    flex: 1 1 calc(33.333% - 1rem);
    margin: 0.5rem;
    box-sizing: border-box;
    text-align: center;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .info img {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }
  
  .info-title {
    font-size: 1.25rem; /* Same as .card-title in product-section */
    margin-bottom: 0.75rem;
  }
  
  .info-text {
    font-size: 1rem; /* Same as .card-text in product-section */
  }
  
  @media (max-width: 1200px) {
    .category {
      flex: 1 1 calc(33.333% - 1rem);
    }
    .info {
      flex: 1 1 calc(50% - 1rem);
    }
  }
  
  @media (max-width: 768px) {
    .category, .info {
      flex: 1 1 calc(50% - 1rem);
    }
  }
  
  @media (max-width: 576px) {
    .category, .info {
      flex: 1 1 100%;
    }
  }
  