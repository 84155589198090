.footer {
    background-color: #1c1c72;
    color: white;
    padding: 40px 20px;
    font-family: 'Roboto', sans-serif;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-logo img {
    width: 150px;
    margin-bottom: 20px;
}

.footer-about,
.footer-tags,
.footer-newsletter {
    flex: 1;
    padding: 0 20px;
}

.footer-about h3,
.footer-tags h4,
.footer-newsletter h4 {
    font-size: 24px;
    margin-bottom: 15px;
}

.footer-about p,
.footer-newsletter p {
    font-size: 16px;
    line-height: 1.5;
}

.link-button {
    background: none;
    border: none;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    font: inherit;
    padding: 0;
}

.link-button:hover {
    color: #00aaff;
}

.newsletter-input {
    display: flex;
    margin-top: 20px;
}

.newsletter-input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px 0 0 5px;
    margin-right: -5px;
}

.newsletter-input button {
    padding: 10px 20px;
    background-color: #00c7b7;
    border: none;
    color: white;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.newsletter-input button:hover {
    background-color: #00a39a;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00c7b7;
    padding: 10px 20px;
    color: white;
    font-size: 14px;
    margin-top: 20px;
}

.footer-bottom p {
    margin: 0;
}

.footer-payment-methods img {
    width: 40px;
    margin-left: 10px;
}
