.product-section {
  padding: 40px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
  color: #333;
}

.product-card {
  margin-bottom: 30px;
}

.product-card .card {
  border: none;
  transition: transform 0.2s ease-in-out;
}

.product-card .card:hover {
  transform: translateY(-10px);
}

.product-card .card-img-top {
  height: 150px;
  object-fit: cover;
}

.product-card .card-body {
  text-align: center;
}

.product-card .card-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.product-card .card-text {
  font-size: 1.1em;
  color: #555;
}

.product-card .btn {
  background-color: #007bff;
  border: none;
  transition: background-color 0.2s ease-in-out;
}

.product-card .btn:hover {
  background-color: #0056b3;
}
