.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  height: 80px;
  margin-right: 0px;
}

.brand-name {
  font-size: 24px;
  font-weight: bold;
  color: #2e3a87; /* Adjust the color as needed */
}
