.carousel-item {
    height: 80vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    background-size: cover;
  }
  
  .carousel-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0 15px;
  }
  
  .carousel-caption h3 {
    font-size: 2.5rem;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  }
  
  .carousel-caption .btn-primary {
    margin-top: 20px;
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
  }
  
  @media (max-width: 768px) {
    .carousel-caption h3 {
      font-size: 1.8rem;
    }
  
    .carousel-caption .btn-primary {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .carousel-caption h3 {
      font-size: 1.2rem;
    }
  
    .carousel-caption .btn-primary {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  